<template>
  <div class="wrap">
    <h1>
      <img src="../../assets/會員相關/titleIcon.svg" alt />
      購買紀錄
    </h1>
    <div class="list">
      <div class="list_head hidden-mobile-down">
        <div>訂單編號</div>
        <div>購買日期</div>
        <div>星盤對象</div>
        <div>產品內容</div>
      </div>
      <div class="list_item" v-for="item in filterList" :key="item.OrderNo">
        <div>
          <div class="list_item_platform">{{ item.Platform }}</div>
          <span>No.{{ item.OrderNo }}</span>
          <br class="hidden-mobile-down" />
          <span
            class="openDetail_btn hidden-mobile-down"
            @click="DetailHandler(item.OrderNo)"
          >
            <img
              src="../../assets/會員相關/maximize.svg"
              class="pr-1"
            />詳細內容
          </span>
          <span
            class="openDetail_btn hidden-mobile-up"
            @click="toDetailPage(item.OrderNo)"
          >
            <img
              src="../../assets/會員相關/maximize.svg"
              class="pr-1"
            />詳細內容
          </span>
        </div>
        <div>{{ item.Date }}</div>
        <div>
          <div class="list_item_platform" v-if="item.ReceiverMemberId">
            贈送給
          </div>
          {{ getTarget(item) }}
        </div>
        <div>
          <button
            v-if="item.ReceiverMemberId"
            class="hasReceiverMember notReady"
          >
            {{ item.ProductName.split("-")[0] }}
          </button>
          <button @click="toUnlockPage(item.OrderNo)" v-else>
            {{ item.ProductName.split("-")[0] }}
          </button>
        </div>
      </div>
      <div class="noRecord" v-if="filterList.length == 0">無任何訂單紀錄喔</div>
    </div>
    <!-- 分頁 -->
    <v-pagination
      v-model="page"
      class="my-4"
      :length="pageLength"
    ></v-pagination>
    <FixedPopup v-if="DetailConent">
      <div class="popupBox">
        <DetailConent
          @sendEmail="sendEmailHandler()"
          :orderData="showOrderData"
          v-if="showOrderData && DetailConent"
        ></DetailConent>

        <div class="close_btn" @click="DetailConent = false">
          <img src="@/assets/會員相關/circle_close.svg" alt />
        </div>
      </div>
    </FixedPopup>
    <SendEmailResult
      v-if="openSendEmailResult"
      text="謝謝您的回報，我們將盡快為您處理"
    />
  </div>
</template>

<script>
import SendEmailResult from "../../components/SendEmailResult";
import FixedPopup from "@/components/Fixed_popup";
import DetailConent from "../../components/會員相關/DetailContent";
export default {
  name: "member-record",
  components: { FixedPopup, DetailConent, SendEmailResult },
  data() {
    return {
      orderList: [],
      page: 1,
      DetailConent: false,
      showOrderData: null,
      openSendEmailResult: false,
    };
  },
  computed: {
    pageLength() {
      return Math.ceil(this.orderList.length / 5) || 1;
    },
    filterList() {
      return this.orderList.slice(5 * (this.page - 1), this.page * 5);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let timer = setInterval(() => {
        if (this.$store.state.token) {
          clearInterval(timer);
          this.$API.Get_PurchaseRecordList().then(({ data }) => {
            this.orderList = data.Data.sort((a, b) => {
              return b.OrderNo - a.OrderNo;
            });
          });
        }
      }, 100);
    },
    DetailHandler(id) {
      this.$API
        .Get_PurchaseRecord(id)
        .then(({ data }) => {
          this.showOrderData = data.Data;
          this.DetailConent = true;
        })
        .catch(() => {
          this.showOrderData = null;
        });
    },
    sendEmailHandler() {
      this.openSendEmailResult = true;
      setTimeout(() => {
        this.openSendEmailResult = false;
        this.showOrderData = null;
        this.DetailConent = false;
      }, 3000);
    },
    toDetailPage(orderId) {
      this.$router.push(`/member/detail/${orderId}`);
    },
    toUnlockPage(id) {
      this.$API.Get_PurchaseRecord(id).then(({ data }) => {
        let order = data.Data;
        switch (order.ProductCode) {
          case "HouseInterpretation":
            //先將好有資訊帶入nowUserData
            if (!order.AstrolabeFriendInfos[0].AstrolabeFileId) {
              return alert(
                "因已解鎖星盤有異動情況，故導致解鎖失效，請恢復異動內容後重新進入星盤。"
              );
            }
            this.$API
              .Get_AstrolabeFile(order.AstrolabeFriendInfos[0].AstrolabeFileId)
              .then((data) => {
                this.$store.commit("set_nowUserData", data.Data);
                return this.$router.push("/astrolabe");
              });
            break;
          case "TransitChart":
            return this.$router.push("/timelineAstrolabe");
          case "MoonNote":
            return this.$router.push("/moonday-list");
          case "ProductVoucher_Book2024":
            return this.$router.push(`/member/detail/${id}`);
          default:
            return this.$router.push("/book2024/my");
        }
      });
    },
    getTarget(item) {
      if (item.ReceiverMemberId) {
        return `${item.ReceiverName || "朋友"}(ID:${item.ReceiverMemberId})`;
      }
      if (item.ProductName.includes("兌換")) {
        return "無";
      }
      if (item.AstrolabeFriend) {
        return item.AstrolabeFriend;
      }
      return "全帳號";
    },
  },
  watch: {
    page() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixin.scss";
.wrap {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @include mobile {
    width: 100%;
  }
  h1 {
    width: 100%;
    font-weight: 500;
    font-size: 40px;
    line-height: 58px;
    color: $theme-color-2;
    display: flex;
    align-items: center;
    @include mobile {
      justify-content: center;
      font-size: 18px;
      line-height: 26px;
      img {
        width: 30px;
      }
    }
  }
  .list {
    width: 100%;
    padding: 60px 20px 0;
    min-height: 70vh;
    @include mobile {
      padding: 0 64px 32px;
    }
    .list_head,
    .list_item {
      width: 100%;
      display: flex;
      @include mobile {
        padding: 0 30px 10px;
        flex-direction: column;
        border-bottom: 1px solid #d9d9d9;
      }
      > div {
        width: 25%;
        @include mobile {
          width: 100%;
          padding: 10px 0;
          &:nth-of-type(2) {
            order: 2;
          }
          &:nth-of-type(4) {
            order: 3;
          }
        }
      }
    }
    .list_head {
      color: $theme-color-1;
      font-size: 18px;
      line-height: 26px;
    }
    .list_item {
      margin-top: 67px;
      font-size: 18px;
      line-height: 26px;
      color: #9b9b9b;
      letter-spacing: 0.06em;
      & > div {
        position: relative;
        @include mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }
      }
      @include mobile {
        margin-top: 40px;
      }

      .list_item_platform {
        position: absolute;
        left: 0;
        top: -25px;
        padding: 2px 12px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
        border-radius: 50px;
        font-size: 12px;
        line-height: 17px;
        display: inline-block;
        @include mobile {
          position: relative;
          top: 0;
          left: 0;
        }
      }
      .openDetail_btn {
        display: flex;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
      }
      button {
        @include btn-default(176px, 56px);
        &.hasReceiverMember {
          @include btn-secondary(176px, 56px);
        }
      }
    }
  }
}
//彈跳視窗
.popupBox {
  padding: 60px 36px;
  width: 660px;
  height: auto;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  position: relative;
  .close_btn {
    position: absolute;
    top: 60px;
    right: 75px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(#9b9b9b, 0.2);
    @include center;
    cursor: pointer;
  }
}
.noRecord {
  min-height: 40vh;
  @include center;
  font-size: 40px;
  line-height: 58px;
  color: $theme-color-2;
}
</style>
